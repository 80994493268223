import { createRouter, createWebHashHistory } from "vue-router"
import { getCookie } from "@/utils/index.js"

const routes = [
	{
		path: "/",
		name: "",
		redirect: "/desk",
	},
	{
		path: "/login",
		name: "login",
		component: () => import("../pages/login/index.vue"),
		meta: {
			requiresAuth: false,
			label:'登录',
		},
	},
	{
		path: "/layout",
		name: "layout",
		component: () => import("../pages/layout/index.vue"),
		meta: {
			requiresAuth: true,
			label:'主框架',
		},
		children: [
			{
				path: "/desk",
				name: "desk",
				component: () => import("../pages/desk/index.vue"),
				meta: {
					requiresAuth: true,
					label:'桌面',
				},
			},
			{
				path: "/projectCenter",
				name: "projectCenter",
				component: () => import("../pages/projectCenter/index.vue"),
				meta: {
					requiresAuth: true,
					label:'项目中心',
				},
			},
		]
	},

	
]

const router = createRouter({
	history: createWebHashHistory(),
	routes,
})
router.beforeEach((to, from, next) => {
	// next()
	let token = getCookie('token')
	if (to.meta.requiresAuth) {
		if (token && token!=null && token != "null") {
			next()
		} else {
			next('/login');
		}
	} else {
		next()
	}
	window.scrollTo(0,0)
})

export default router

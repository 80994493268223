import { ElMessage } from 'element-plus'
/**
 * 存储localStorage
 */
export const setStoreage = (name, content) => {
	if (!name) return;
	if (typeof content !== 'string') {
		content = JSON.stringify(content);
	}
	window.localStorage.setItem(name, content);
}

/**
 * 获取localStorage
 */
export const getStoreage = name => {
	if (!name) return;
	return window.localStorage.getItem(name);
}

/**
 * 删除localStorage
 */
export const removeStoreage = name => {
	if (!name) return;
	window.localStorage.removeItem(name);
}

/**
 * 存储sessionStorage
 */
export const setSession = (name, content) => {
    if (!name) return;
    if (typeof content !== 'string') {
        content = JSON.stringify(content);
    }
    window.sessionStorage.setItem(name, content);
}

/**
 * 获取sessionStorage
 */
export const getSession = name => {
    if (!name) return;
    return window.sessionStorage.getItem(name);
}

/**
 * 删除sessionStorage
 */
export const removeSession = name => {
    if (!name) return;
    window.sessionStorage.removeItem(name);
}


/**
 * 存储cookie
 */
export const setCookie = (name , value) => {
    if (!name) return;
    document.cookie = name + "="+ escape (value) ; 
}

/**
 * 读取cookie
 */
export const getCookie =  name => { 
    var arr,reg=new RegExp("(^| )"+name+"=([^;]*)(;|$)"); 
    if(arr=document.cookie.match(reg)){
        return unescape(arr[2]); 
    }
    else{
        return null;
    }          
} 

/**
 * 删除cookie
 */
export const delCookie = (name) => { 
    var cval=getCookie(name); 
    if(cval!=null){
        document.cookie= name + "=null";  
    } 
}

//复制
export const copyTxt =(txt)=>{
    if (document.queryCommandSupported('copy')) {
        let textarea = document.createElement("textarea")
        textarea.value = txt
        textarea.readOnly = "readOnly"
        document.body.appendChild(textarea)
        textarea.select() // 选中文本内容
        textarea.setSelectionRange(0, txt.length) 
        let result = document.execCommand("copy") 
        textarea.remove()
        ElMessage({
            message: '复制成功！',
            type: 'success',
        }) 
    }else{
        ElMessage({
            message: '您的浏览器不支持复制',
            type: 'warning',
        })
    }
}
